type LayoutShiftEntry = PerformanceEntry & {
  hadRecentInput: boolean;
  value: number;
};

export class PerformanceMetrics {
  static init(): void {
    if ("PerformanceObserver" in window) {
      // FCP (First Contentful Paint)
      new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        entries.forEach((entry) => {});
      }).observe({ entryTypes: ["paint"] });

      // LCP (Largest Contentful Paint)
      new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        entries.forEach((entry) => {});
      }).observe({ entryTypes: ["largest-contentful-paint"] });

      // CLS (Cumulative Layout Shift)
      new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        entries.forEach((entry) => {
          const layoutShift = entry as LayoutShiftEntry;
          if (!layoutShift.hadRecentInput) {
          }
        });
      }).observe({ entryTypes: ["layout-shift"] });
    }
  }
}
