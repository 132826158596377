import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { PerformanceMetrics } from "./types/PerformanceMetrics";
import { reportWebVitals } from "./types/reportWebVitals";

// Lazy load App component
const App = React.lazy(() => import("./App"));

// Create the root for React 18
const container = document.getElementById("root");
const root = createRoot(container!);

// Initialize performance monitoring
PerformanceMetrics.init();

// Render with Suspense for lazy loading
root.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
          }}
        >
          Loading...
        </div>
      }
    >
      <App />
    </React.Suspense>
  </React.StrictMode>,
);

// Report web vitals
reportWebVitals((metric) => {
  // Send to analytics
});
